import { Animator, Step, Timeline } from "optimo-animator";
import { setCss } from "./utils";
import { Writer } from "./Writer";
import { fonts } from "./fonts";

enum Actions {
  Start = "start",
  Stop = "stop",
  Reset = "reset",
}

const MAX_RATIO = 2.2;
const MID_RATIO = 1.7;

const isMid = () => {
  return (
    window.innerWidth / window.innerHeight >= MID_RATIO &&
    window.innerWidth / window.innerHeight < MAX_RATIO
  );
};

const isMobile = () => {
  return window.innerWidth / window.innerHeight < MID_RATIO;
};

(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const delay = urlParams.get("delay") === "true";

  const texts = [
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
    "Кириллица",
    "Latin",
    "Ελληνικα",
    "Волосяное",
    "Hairline",
    "Ισχνα",
    "Сверхтонкое",
    "Ultralight",
    "Υπερλευκα",
    "Тонкое",
    "Thin",
    "Λεπτα",
    "Светлое",
    "Light",
    "Λευκα",
    "Нормальное",
    "Regular",
    "Κανονικα",
    "Полужирное",
    "Medium",
    "Μεσαια",
    "Жирное",
    "Bold",
    "Μαυρα",
    "Сверхжирное",
    "Heavy",
    "Βαρεα",
    "Ультражирное",
    "Black",
    "Μαυρα",
  ];

  const textsMobile = [
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
    "Кириллица",
    "Latin",
    "Ελληνικα",
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
    "Тайнхарт",
    "Theinhardt",
    "Ταινχαρτ",
  ];
  const lines = Array.from(document.querySelectorAll(".line")) as HTMLElement[];
  const writers = lines.map((line) => new Writer(line));
  let textIndex = 0;

  const setLine = (writer: Writer, reset = true) => {
    const fontIndex = Math.floor(textIndex / 3);
    const font = fonts[fontIndex];

    const lineIndex = textIndex % 3;
    setCss(lines[lineIndex], {
      fontFamily: font.group,
      fontWeight: `${font.weight}`,
    });

    const t = isMobile() ? textsMobile : texts;

    writer.setText(t[textIndex]);

    if (reset) {
      writer.reset();
    }

    textIndex++;
    textIndex %= texts.length;
  };

  writers.forEach((writer) => {
    setLine(writer, false);
  });

  const steps: Step[] = writers.map((writer) => ({
    duration: 1000,
    onStart() {
      setLine(writer);
    },
    handler(progress) {
      writer.render(progress);
    },
  }));

  const timeline = new Timeline(steps);
  const animator = new Animator([timeline]);

  if (delay) {
    animator.stop();
    animator.startTime = 0;
    animator.computeFrameAt(0);
    window.addEventListener(
      "message",
      ({ data }) => {
        const { action } = data;
        if (action === Actions.Start) {
          animator.start();
        } else if (action === Actions.Stop) {
          animator.stop();
        } else if (action === Actions.Reset) {
          animator.startTime = 0;
          animator.computeFrameAt(0);
          textIndex = 0;
          animator.startTime = Date.now();
          writers.forEach((writer) => {
            setLine(writer, false);
          });
        }
      },
      false
    );
  } else {
    animator.start();
  }

  const updateIsMobile = () => {
    const lines = document.querySelector(".lines");

    if (isMobile()) {
      lines?.classList.add("is-mobile");
    } else {
      lines?.classList.remove("is-mobile");
    }

    if (isMid()) {
      lines?.classList.add("is-mid");
    } else {
      lines?.classList.remove("is-mid");
    }
  };

  updateIsMobile();
  window.addEventListener("resize", updateIsMobile);

  // document.addEventListener("visibilitychange", () => {
  //   if (document.hidden) {
  //     animator.stop();
  //   } else {
  //     textIndex = 0;
  //     animator.startTime = Date.now();
  //     writers.forEach((writer) => {
  //       setLine(writer, false);
  //     });
  //     animator.start();
  //   }
  // });
})();
