"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Animator = void 0;
var Animator = /** @class */ (function () {
    function Animator(timelines) {
        this._startTime = Date.now();
        this._timelines = timelines;
        this._animationFrame = null;
    }
    Object.defineProperty(Animator.prototype, "isRunning", {
        get: function () {
            return Boolean(this._animationFrame);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Animator.prototype, "startTime", {
        get: function () {
            return this._startTime;
        },
        set: function (startTime) {
            this._startTime = startTime;
        },
        enumerable: false,
        configurable: true
    });
    Animator.prototype.onTick = function (onTick) {
        this._onTick = onTick;
    };
    Animator.prototype.onStep = function (onStep) {
        this._onStep = onStep;
    };
    Animator.prototype.computeFrameAt = function (time) {
        var _a;
        var elapsedTime = time - this._startTime;
        for (var _i = 0, _b = this._timelines; _i < _b.length; _i++) {
            var timeline = _b[_i];
            var timelineTime = elapsedTime % timeline.totalDuration;
            var step = timeline.getCurrentStep(timelineTime);
            if (step) {
                var durationUntilStep = timeline.getDurationUntilStep(step);
                var stepTime = (timelineTime - durationUntilStep) / step.duration;
                /**
                 * Trigger the on start callback on the first frame of a step.
                 */
                if (step !== timeline.lastStep || timeline.lastStepTime > stepTime) {
                    if ((_a = timeline.lastStep) === null || _a === void 0 ? void 0 : _a.onEnd) {
                        timeline.lastStep.onEnd();
                    }
                    if (step.onStart) {
                        step.onStart();
                    }
                    if (this._onStep) {
                        this._onStep(elapsedTime, step);
                    }
                }
                if (step.handler) {
                    step.handler(stepTime);
                }
                if (this._onTick) {
                    this._onTick(elapsedTime, step);
                }
                timeline.lastStepTime = stepTime;
                timeline.lastStep = step;
            }
            else {
                this.stop();
            }
        }
    };
    Animator.prototype.loop = function () {
        var _this = this;
        this._animationFrame = requestAnimationFrame(function () {
            _this.computeFrameAt(Date.now());
            _this.loop();
        });
    };
    Animator.prototype.start = function () {
        this._startTime = Date.now();
        this.loop();
    };
    Animator.prototype.stop = function () {
        if (this._animationFrame) {
            cancelAnimationFrame(this._animationFrame);
        }
        this._animationFrame = null;
    };
    return Animator;
}());
exports.Animator = Animator;
