export type Font = {
  name: string;
  type: string;
  group: string;
  weight: number;
};

export const fonts: Font[] = [
  {
    name: "Theinhardt-Pan-Medium",
    type: "Medium",
    group: "Theinhardt-Pan",
    weight: 600,
  },
  {
    name: "Theinhardt-Pan-Medium",
    type: "Medium",
    group: "Theinhardt-Pan",
    weight: 600,
  },
  {
    name: "Theinhardt-Pan-Hairline",
    type: "Hairline",
    group: "Theinhardt-Pan",
    weight: 100,
  },
  {
    name: "Theinhardt-Pan-Ultralight",
    type: "Ultralight",
    group: "Theinhardt-Pan",
    weight: 200,
  },
  {
    name: "Theinhardt-Pan-Thin",
    type: "Thin",
    group: "Theinhardt-Pan",
    weight: 300,
  },
  {
    name: "Theinhardt-Pan-Light",
    type: "Light",
    group: "Theinhardt-Pan",
    weight: 400,
  },
  {
    name: "Theinhardt-Pan-Regular",
    type: "Regular",
    group: "Theinhardt-Pan",
    weight: 500,
  },
  {
    name: "Theinhardt-Pan-Medium",
    type: "Medium",
    group: "Theinhardt-Pan",
    weight: 600,
  },
  {
    name: "Theinhardt-Pan-Bold",
    type: "Bold",
    group: "Theinhardt-Pan",
    weight: 700,
  },
  {
    name: "Theinhardt-Pan-Heavy",
    type: "Heavy",
    group: "Theinhardt-Pan",
    weight: 800,
  },
  {
    name: "Theinhardt-Pan-Black",
    type: "Black",
    group: "Theinhardt-Pan",
    weight: 900,
  },
];
