import { setCss } from "./utils";

export class Writer {
  private text: string;
  public element: HTMLElement;

  constructor(element: HTMLElement) {
    this.element = element;
  }

  reset() {
    const letters = Array.from(this.element.children) as HTMLElement[];
    for (const letter of letters) {
      setCss(letter, {
        opacity: "0",
      });
    }
  }

  setText(text: string) {
    this.text = text;
    this.element.innerHTML = "";
    for (const letter of this.text) {
      const span = document.createElement("span");
      span.innerText = letter;
      this.element.appendChild(span);
    }
  }

  render(progress: number) {
    let letterIndex = 0;
    const letters = Array.from(this.element.children) as HTMLElement[];
    for (const letter of letters) {
      if (letterIndex < Math.floor(progress * (letters.length + 1))) {
        setCss(letter, {
          opacity: "1",
        });
      }
      letterIndex++;
    }
  }
}
